@media (prefers-color-scheme: light) {
  .styles_wrapper__7oAL5 {
    --box-border-color: #e5e7eb;
    --color: #1f2937;
    --bg-color: #f3f4f6;
    --btn-color: #1f2937;
    --btn-bg-color: #e5e7eb;
    --btn-color-hover: #1f2937;
    --btn-bg-color-hover: #d1d5db;
  }
}
@media (prefers-color-scheme: dark) {
  .styles_wrapper__7oAL5 {
    --box-border: #111827;
    --color: #f3f4f6;
    --bg-color: #111827;
    --btn-color: #f3f4f6;
    --btn-bg-color: #1f2937;
    --btn-color-hover: #f3f4f6;
    --btn-bg-color-hover: #334155;
  }
}
.styles_wrapper__7oAL5 > .mep-next-iubenda-loading,
.styles_wrapper__7oAL5 > .mep-next-iubenda-consent-not-granted {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 2rem;
  width: 100%;
  text-align: center;
  color: var(--color);
  border-radius: 0.375rem;
  background-color: var(--bg-color);
  border: 1px solid var(--box-border-color);
}
.styles_wrapper__7oAL5 > .mep-next-iubenda-loading button,
.styles_wrapper__7oAL5 > .mep-next-iubenda-consent-not-granted button {
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  color: var(--btn-color);
  border-radius: 0.375rem;
  background-color: var(--btn-bg-color);
}
.styles_wrapper__7oAL5 > .mep-next-iubenda-loading button:hover,
.styles_wrapper__7oAL5 > .mep-next-iubenda-consent-not-granted button:hover {
  color: var(--btn-color-hover);
  background-color: var(--btn-bg-color-hover);
}

/* thai */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/8864cd68b47204b1-s.woff2) format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/1534a86b6e214271-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/aff5660443c0207e-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/4e2c80f5dca7beb1-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/71a4672c2abf5dd8-s.woff2) format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/cf1f7438a625d9dd-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/0662a21957bec227-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/22022a31c7744ae2-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/b43c3dff281aa0f4-s.woff2) format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/90f679ce0b66b7dc-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/9f2d9d817802fec7-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/8b63da99a0839279-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/1f5870e4d23c50a3-s.woff2) format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/667330caa9d6a604-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/6ad75ef0fb754228-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/bba7371df18c8ce0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/02621ac2b51e3f46-s.woff2) format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/754d7952a22021ac-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/4cd5d279e155a41a-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/049e3b085259587b-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/7e904eeb807f5cf1-s.woff2) format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/1c6d7a5ca3a597bb-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/a3c1b851c74619d3-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/072f0649010d1e65-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/7ca2dd3ca62fea2a-s.woff2) format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/d3dc623d6b2a265e-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/2c594408ef685395-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/cd2a5d09e2651f08-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/82cbc8b1ed7700fc-s.woff2) format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/a313b852ed8c9cd7-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/86b8beeab84a0e1e-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/0d5e1711a21c2592-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/8d44bdee1ace6ba1-s.woff2) format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/9ce3db1cfe427eca-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/b57adafba379a456-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/c285914675b95248-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/72d0a34530f270f0-s.woff2) format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/a751395f3948a994-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/691dbb4d32997029-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/ee285b05ac47a625-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/a79adedcf0febccf-s.woff2) format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/a5f70e54ebd63c97-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/677cb73e6aafb8b4-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/3a300a8b42167374-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/b73b5171e099f3f2-s.woff2) format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/3486cb11bd1f7b40-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/41d17167fed63e55-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/5e3f6c60935cfc84-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/153cddd40fc006c4-s.woff2) format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/93780db435bead85-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/8e7550267a9c8154-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/1de9c871cd3a3669-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/be1c948db3b3e474-s.woff2) format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/412c6a47866a3d70-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/c0c66eb32ed150f6-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/67be4c401af36c57-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/1a814473920b47c4-s.woff2) format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/ce5a800ee6ddbbac-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/2645a1a789b6057a-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/de69c930925b36eb-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/79062e96da184f58-s.woff2) format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/29ed076d7aa8314b-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/76a197fac73c1c1c-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/bf54e24be5d8358f-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/9cb18dc3dc0d29d1-s.woff2) format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/5cba34cd7a26e1d2-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/a5eae6f8c3a5ba73-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/520bf67e47a6ea28-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/b61a2882d529b53d-s.woff2) format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/f193af2913df7eff-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/70e2308514b0005b-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Kanit_746dd7';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/02701f268eb7de16-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Kanit_Fallback_746dd7';src: local("Arial");ascent-override: 108.49%;descent-override: 38.96%;line-gap-override: 0.00%;size-adjust: 101.39%
}.__className_746dd7 {font-family: '__Kanit_746dd7', '__Kanit_Fallback_746dd7'
}

